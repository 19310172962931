import axios from 'axios'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import '../styles/matchesdisplay.css'

const API_BASE_URL = 'https://mingle-server-liard.vercel.app'

const MatchesDisplay = ({ matches, setClickedUser }) => {
  const [matchedProfiles, setMatchedProfiles] = useState([])
  const [loading, setLoading] = useState(true)
  const [cookies] = useCookies(['user'])
  const userId = cookies.UserId

  const matchedUserIds = matches.map(({ user_id }) => user_id)

  const getMatches = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_BASE_URL}/users`, {
        params: { userIds: JSON.stringify(matchedUserIds) },
      })
      setMatchedProfiles(response.data)
    } catch (error) {
      console.error('Error fetching matches:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMatches()
  }, [matches])

  const filteredMatchedProfiles = matchedProfiles.filter((matchedProfile) =>
    matchedProfile.matches.some((profile) => profile.user_id === userId)
  )

  return (
    <div className='matches-display'>
      {loading ? (
        <div className='loading-container'>
          <div className='spinner'></div>
          <p>Loading your matches...</p>
        </div>
      ) : filteredMatchedProfiles.length ? (
        filteredMatchedProfiles.map((match) => (
          <div
            key={match.user_id}
            className='match-card'
            onClick={() => setClickedUser(match)}
          >
            <div className='match-img-container'>
              <img
                src={match.url || '/default-profile.png'}
                alt={`${match.first_name}'s profile`}
                className='match-img'
              />
            </div>
            <div className='match-info'>
              <h3>{match.first_name}</h3>
              <p>Tap to chat</p>
            </div>
          </div>
        ))
      ) : (
        <div className='no-matches'>
          <p>No matches yet. Start swiping to connect!</p>
        </div>
      )}
    </div>
  )
}

export default MatchesDisplay
