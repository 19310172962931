import Chat from './Chat'
import ChatInput from './ChatInput'
import axios from 'axios'
import { useState, useEffect } from 'react'
import '../styles/chatdisplay.css'

const API_BASE_URL = 'https://mingle-server-liard.vercel.app'

const ChatDisplay = ({ user, clickedUser }) => {
  const userId = user?.user_id
  const clickedUserId = clickedUser?.user_id
  const [usersMessages, setUsersMessages] = useState(null)
  const [clickedUsersMessages, setClickedUsersMessages] = useState(null)

  const getUsersMessages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/messages`, {
        params: { userId, correspondingUserId: clickedUserId },
      })
      setUsersMessages(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getClickedUsersMessages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/messages`, {
        params: { userId: clickedUserId, correspondingUserId: userId },
      })
      setClickedUsersMessages(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getUsersMessages()
    getClickedUsersMessages()
  }, [clickedUserId])

  const messages = [
    ...(usersMessages || []).map((message) => ({
      name: user?.first_name,
      img: user?.url,
      message: message.message,
      timestamp: message.timestamp,
      isUser: true,
    })),
    ...(clickedUsersMessages || []).map((message) => ({
      name: clickedUser?.first_name,
      img: clickedUser?.url,
      message: message.message,
      timestamp: message.timestamp,
      isUser: false,
    })),
  ].sort((a, b) => a.timestamp.localeCompare(b.timestamp))

  return (
    <div className='chat-display-container'>
      <Chat descendingOrderMessages={messages} />
      <ChatInput
        user={user}
        clickedUser={clickedUser}
        getUserMessages={getUsersMessages}
        getClickedUsersMessages={getClickedUsersMessages}
      />
    </div>
  )
}

export default ChatDisplay
