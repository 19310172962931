import React, { useState, useEffect } from 'react'
import ChatHeader from './ChatHeader'
import MatchesDisplay from './MatchesDisplay'
import ChatDisplay from './ChatDisplay'
import '../styles/chatcontainer.css'

const ChatContainer = ({ user }) => {
  const [clickedUser, setClickedUser] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (clickedUser) {
      setLoading(true)
      const timeout = setTimeout(() => setLoading(false), 1000) // Simulate loading time
      return () => clearTimeout(timeout)
    }
  }, [clickedUser])

  return (
    <div className='chat-container'>
      {/* Header */}
      <ChatHeader user={user} />

      {/* Sidebar */}
      <div className='chat-sidebar'>
        <button
          className={`sidebar-tab ${!clickedUser ? 'active' : ''}`}
          onClick={() => setClickedUser(null)}
        >
          Matches
        </button>
        <button
          className={`sidebar-tab ${clickedUser ? 'active' : ''}`}
          onClick={() => setClickedUser(clickedUser)}
          disabled={!clickedUser}
        >
          Chat
        </button>
      </div>

      {/* Main Content */}
      <div className='chat-main'>
        {loading ? (
          <div className='spinner-container'>
            <div className='spinner'></div>
          </div>
        ) : !clickedUser ? (
          <MatchesDisplay
            matches={user.matches}
            setClickedUser={setClickedUser}
          />
        ) : user.matches.length === 0 ? (
          <div className='no-data'>
            No matches found. <a href='/swipe'>Go to swipe</a> and start
            exploring!
          </div>
        ) : (
          <ChatDisplay user={user} clickedUser={clickedUser} />
        )}
      </div>
    </div>
  )
}

export default ChatContainer
