import { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import whiteLogo from '../images/mingo-logo-white.png'
import colorLogo from '../images/mingo-logo-color.png'
import '../styles/nav.css'

const Nav = ({ authToken, minimal, setShowModal, showModal, setIsSignUp }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev)
  }

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false)
  }

  const handleLoginClick = () => {
    setShowModal(true)
    setIsSignUp(false)
    closeMobileMenu() // Close mobile menu when navigating
  }

  return (
    <nav>
      {/* Logo Section */}
      <div className='logo-container'>
        <a href='/' onClick={closeMobileMenu}>
          <img
            className='logo'
            src={minimal ? colorLogo : whiteLogo}
            alt='Mingo Logo'
          />
        </a>
      </div>

      {/* Navigation Links for Large Screens */}
      <div className='nav-links'>
        <a href='/learn'>Learn</a>
        <a href='/safety'>Safety</a>
        <a href='/support'>Support</a>
        {!authToken && !minimal && (
          <button
            className='nav-button'
            onClick={handleLoginClick}
            disabled={showModal}
          >
            Log in
          </button>
        )}
        {authToken && (
          <a href='/dashboard' className='nav-button'>
            Dashboard
          </a>
        )}
      </div>

      {/* Hamburger Icon for Mobile */}
      <div
        className='hamburger'
        onClick={toggleMobileMenu}
        aria-label='Toggle navigation menu'
      >
        <FaBars size={24} color='#fff' />
      </div>

      {/* Mobile Menu */}
      <div
        className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}
        aria-expanded={isMobileMenuOpen}
      >
        <a href='/learn' onClick={closeMobileMenu}>
          Learn
        </a>
        <a href='/safety' onClick={closeMobileMenu}>
          Safety
        </a>
        <a href='/support' onClick={closeMobileMenu}>
          Support
        </a>
        {!authToken && !minimal && (
          <button
            className='secondary-button'
            onClick={handleLoginClick}
            disabled={showModal}
          >
            Log in
          </button>
        )}
        {authToken && (
          <a
            href='/dashboard'
            className='secondary-button'
            onClick={closeMobileMenu}
          >
            Dashboard
          </a>
        )}
      </div>
    </nav>
  )
}

export default Nav
