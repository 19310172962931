import { useState, useCallback } from 'react'
import axios from 'axios'
import '../styles/chatinput.css'

const API_BASE_URL = 'https://mingle-server-liard.vercel.app'

const ChatInput = ({
  user,
  clickedUser,
  getUserMessages,
  getClickedUsersMessages,
}) => {
  const [textArea, setTextArea] = useState('')
  const [error, setError] = useState(null)
  const userId = user?.user_id
  const clickedUserId = clickedUser?.user_id

  const addMessage = useCallback(async () => {
    if (!textArea.trim()) {
      setError('Message cannot be empty.')
      return
    }

    const message = {
      timestamp: new Date().toISOString(),
      from_userId: userId,
      to_userId: clickedUserId,
      message: textArea,
    }

    try {
      await axios.post(`${API_BASE_URL}/message`, { message })
      getUserMessages()
      getClickedUsersMessages()
      setTextArea('')
      setError(null)
    } catch (error) {
      console.error(error)
      setError('Failed to send the message. Please try again.')
    }
  }, [
    textArea,
    userId,
    clickedUserId,
    getUserMessages,
    getClickedUsersMessages,
  ])

  return (
    <div className='chat-input'>
      <textarea
        aria-label='Type your message here'
        placeholder='Type a message...'
        value={textArea}
        onChange={(e) => {
          setTextArea(e.target.value)
          if (error) setError(null)
        }}
        className='chat-textarea'
      />
      <button
        className='send-button'
        onClick={addMessage}
        disabled={!textArea.trim()}
      >
        Send
      </button>
      {error && <p className='error-message'>{error}</p>}
    </div>
  )
}

export default ChatInput
