import Nav from '../components/Nav'
import '../styles/support.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faPhone,
  faEnvelope,
  faLifeRing,
} from '@fortawesome/free-solid-svg-icons'

const Support = () => {
  return (
    <>
      <Nav minimal={false} />
      <div className='support-page'>
        {/* Header Section */}
        <header className='support-header'>
          <h1>We're Here to Help!</h1>
          <p>
            Have questions or need assistance? Browse our resources below or
            reach out to our support team.
          </p>
          <div className='search-bar'>
            <input
              type='text'
              placeholder='Search for help...'
              aria-label='Search support'
            />
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </header>

        {/* Main Content */}
        <section className='support-content'>
          {/* FAQs Section */}
          <div className='support-card'>
            <FontAwesomeIcon icon={faLifeRing} className='support-icon' />
            <h2>FAQs</h2>
            <p>
              Find answers to common questions in our{' '}
              <a href='/faq'>FAQ section</a>.
            </p>
          </div>

          {/* Contact Section */}
          <div className='support-card'>
            <FontAwesomeIcon icon={faPhone} className='support-icon' />
            <h2>Contact Us</h2>
            <p>
              Reach out to our team via{' '}
              <a href='mailto:support@mingo.com'>email</a> or call{' '}
              <strong>1-800-555-1234</strong>.
            </p>
          </div>

          {/* Report an Issue Section */}
          <div className='support-card'>
            <FontAwesomeIcon icon={faEnvelope} className='support-icon' />
            <h2>Report an Issue</h2>
            <p>
              Found a bug? Let us know by filling out our{' '}
              <a href='/report-issue'>Issue Report Form</a>.
            </p>
          </div>
        </section>

        {/* Additional Content */}
        <section className='extra-content'>
          <div className='testimonials'>
            <h2>What Our Users Say</h2>
            <blockquote>
              "Mingo's support team is fantastic! They resolved my issue in no
              time." – Alex P.
            </blockquote>
            <blockquote>
              "The FAQ section is super helpful. Found answers to all my
              questions!" – Taylor R.
            </blockquote>
          </div>
          <div className='quick-tips'>
            <h2>Quick Tips</h2>
            <ul>
              <li>Make sure your profile is complete to get better matches.</li>
              <li>Check your email spam folder for missed notifications.</li>
              <li>Use strong, unique passwords for account security.</li>
            </ul>
          </div>
        </section>

        {/* Footer */}
        <footer className='support-footer'>
          <p>
            Need more help? Visit our <a href='/community'>Community Forum</a>{' '}
            for peer-to-peer support.
          </p>
        </footer>
      </div>
    </>
  )
}

export default Support
