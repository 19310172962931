import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import '../styles/chatheader.css'

const ChatHeader = ({ user }) => {
  const [cookies, , removeCookie] = useCookies(['user'])
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  const logout = () => {
    removeCookie('UserId', cookies.UserId)
    removeCookie('AuthToken', cookies.AuthToken)
    navigate('/') // Redirect to home page
    window.location.reload() // Reload to clear state
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header className='chat-header'>
      {/* Profile Section */}
      <div className='profile'>
        <div className='img-container'>
          <img src={user.url} alt={`Photo of ${user.first_name}`} />
        </div>
        <h3 className='user-name'>{user.first_name}</h3>
      </div>

      {/* Navigation Menu */}
      <div className={`navigation-menu ${menuOpen ? 'open' : ''}`}>
        <button
          className='navigate-btn'
          onClick={() => {
            navigate('/dashboard')
            toggleMenu()
          }}
        >
          Dashboard
        </button>
        <button
          className='navigate-btn'
          onClick={() => {
            navigate('/swipe')
            toggleMenu()
          }}
        >
          Swipe
        </button>
        <button
          className='logout-btn'
          onClick={() => {
            logout()
            toggleMenu()
          }}
        >
          <FaSignOutAlt className='logout-icon' />
          Log Out
        </button>
      </div>

      {/* Hamburger Menu */}
      <div className='hamburger-menu' onClick={toggleMenu}>
        {menuOpen ? (
          <FaTimes className='menu-icon' />
        ) : (
          <FaBars className='menu-icon' />
        )}
      </div>
    </header>
  )
}

export default ChatHeader
