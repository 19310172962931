import { useEffect, useState } from 'react'
import ChatContainer from '../components/ChatContainer'
import axios from 'axios'
import { useCookies } from 'react-cookie'

const Dashboard = () => {
  const [user, setUser] = useState(null)
  const [cookies] = useCookies(['user'])
  const userId = cookies.UserId

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          'https://mingle-server-liard.vercel.app/user',
          { params: { userId } }
        )
        setUser(response.data)
      } catch (error) {
        console.error('Error fetching user:', error)
      }
    }

    if (userId) {
      fetchUser()
    }
  }, [userId])

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <div className='dashboard'>
      <ChatContainer user={user} />
    </div>
  )
}

export default Dashboard
