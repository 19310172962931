import Nav from '../components/Nav';
import '../styles/safety.css';

const Safety = () => {
  return (
    <>
      <Nav minimal={false} />
      <div className="safety-page">
        {/* Header Section */}
        <header className="safety-header">
          <h1>Your Safety Matters</h1>
          <p>
            At Mingo, your safety is our top priority. Follow these guidelines to ensure secure and positive interactions on our platform.
          </p>
        </header>

        {/* Main Content */}
        <section className="safety-content">
          {/* Protect Your Information */}
          <div className="safety-card">
            <h2>Protect Your Personal Information</h2>
            <p>
              Never share sensitive details like your home address, workplace, financial information, or other personal data with someone you've just met.
            </p>
          </div>

          {/* Communicate Through the Platform */}
          <div className="safety-card">
            <h2>Communicate Through the Platform</h2>
            <p>
              Use Mingo's messaging features to communicate until you feel confident and comfortable sharing other contact details.
            </p>
          </div>

          {/* Spotting Red Flags */}
          <div className="safety-card">
            <h2>Spotting Red Flags</h2>
            <p>
              Beware of requests for money, inconsistent stories, or attempts to manipulate your trust. Report suspicious activity to our support team immediately.
            </p>
          </div>

          {/* Meet Safely */}
          <div className="safety-card">
            <h2>Meet Safely</h2>
            <p>
              When meeting in person, choose a public place, inform a friend or family member, and keep your phone fully charged.
            </p>
          </div>

          {/* Tips for Building Trust */}
          <div className="safety-card">
            <h2>Tips for Building Trust</h2>
            <p>
              Take your time to get to know someone. Build a connection gradually and trust your instincts. If something feels off, don't ignore it.
            </p>
          </div>

          {/* How to Report Issues */}
          <div className="safety-card">
            <h2>Reporting and Blocking</h2>
            <p>
              If you encounter inappropriate behavior, you can report or block a user directly from their profile. Our team will investigate and take appropriate action.
            </p>
          </div>
        </section>

        {/* Footer Section */}
        <footer className="safety-footer">
          <p>
            Need help? Visit our <a href="/support">Support Page</a> or contact us directly at{' '}
            <a href="mailto:safety@mingo.com">safety@mingo.com</a>.
          </p>
        </footer>
      </div>
    </>
  );
};

export default Safety;
