import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TinderCard from 'react-tinder-card'
import ChatHeader from '../components/ChatHeader'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import '../styles/swipe.css'

const SwipePage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [cookies] = useCookies(['user'])
  const [user, setUser] = useState(location.state?.user || null)
  const [genderedUsers, setGenderedUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [lastDirection, setLastDirection] = useState('')

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        'https://mingle-server-liard.vercel.app/user',
        { params: { userId: cookies.UserId } }
      )
      setUser({ ...response.data, matches: response.data.matches || [] }) // Ensure matches is an array
    } catch (error) {
      console.error('Error fetching user:', error)
      navigate('/dashboard') // Redirect to dashboard if user cannot be fetched
    }
  }

  const fetchGenderedUsers = async () => {
    if (!user) return // Ensure user exists before fetching
    try {
      const response = await axios.get(
        'https://mingle-server-liard.vercel.app/gendered-users',
        { params: { gender: user.gender_interest } }
      )

      // Filter out already matched users
      const unmatchedUsers = response.data.filter(
        (genderedUser) => !user.matches.includes(genderedUser.user_id)
      )

      setGenderedUsers(unmatchedUsers)
      setLoading(false) // Stop loading when data is ready
    } catch (error) {
      console.error('Error fetching gendered users:', error)
      setLoading(false) // Stop loading even on error
    }
  }

  const updateMatches = async (matchedUserId) => {
    try {
      const response = await axios.put(
        'https://mingle-server-liard.vercel.app/addmatch',
        { userId: user.user_id, matchedUserId }
      )

      // Update the logged-in user's matches
      setUser((prevUser) => ({
        ...prevUser,
        matches: response.data.matches || [],
      }))

      // Remove the matched user from the swipe list
      setGenderedUsers((prev) =>
        prev.filter((genderedUser) => genderedUser.user_id !== matchedUserId)
      )
    } catch (error) {
      console.error('Error updating matches:', error)
    }
  }

  const swiped = async (direction, swipedUserId) => {
    if (direction === 'right') {
      await updateMatches(swipedUserId)
    }
    setLastDirection(direction)
  }

  const outOfFrame = (name) => {
    console.log(`${name} left the screen!`)
  }

  useEffect(() => {
    if (!user) {
      fetchUser() // Fetch user if not available
    } else {
      fetchGenderedUsers()
    }
  }, [user])

  if (loading) {
    return (
      <div className='loading-container'>
        <div className='spinner'></div>
      </div>
    )
  }

  return (
    <div className='swipe-page'>
      <ChatHeader user={user} />

      <div className='welcome-text'>
        <h2>Welcome, {user.first_name || 'User'}!</h2>
        <p>
          Swipe right to connect, or left to skip. Find someone who matches your
          vibe!
        </p>
      </div>

      <div className='card-container'>
        {genderedUsers.length > 0 ? (
          genderedUsers.map((genderedUser) => (
            <TinderCard
              className='swipe'
              key={genderedUser.user_id}
              onSwipe={(dir) => swiped(dir, genderedUser.user_id)}
              onCardLeftScreen={() => outOfFrame(genderedUser.first_name)}
              preventSwipe={['up', 'down']}
            >
              <div className='card'>
                <img
                  src={genderedUser.url}
                  alt={genderedUser.first_name}
                  className='profile-pic'
                />
                <h3 className='profile-name'>{genderedUser.first_name}</h3>
              </div>
            </TinderCard>
          ))
        ) : (
          <div className='no-more-profiles'>
            <h3>You're all caught up!</h3>
            <p>
              No more profiles to explore right now. Check back later for new
              connections.
            </p>
          </div>
        )}
      </div>

      {lastDirection && (
        <p className='last-action'>You swiped {lastDirection}</p>
      )}
    </div>
  )
}

export default SwipePage
