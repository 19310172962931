import Nav from '../components/Nav'
import '../styles/learn.css'

const Learn = () => {
  return (
    <>
      <Nav minimal={false} />
      <div className='learn-page'>
        {/* Header Section */}
        <header className='learn-header'>
          <h1>Learn to Love, Safely and Confidently</h1>
          <p>
            Discover expert tips, tutorials, and resources to make the most out
            of your Mingo experience. Whether you’re new to online dating or a
            seasoned user, we’ve got you covered!
          </p>
        </header>

        {/* Main Content */}
        <section className='learn-resources'>
          {/* Getting Started */}
          <div className='resource-card'>
            <h2>Getting Started</h2>
            <p>
              Learn the basics of setting up your profile, choosing the perfect
              photos, and writing a bio that gets noticed.
            </p>
            <a href='/resources/getting-started' className='learn-button'>
              Explore
            </a>
          </div>

          {/* Making Connections */}
          <div className='resource-card'>
            <h2>Making Connections</h2>
            <p>
              Find out how to start meaningful conversations and navigate the
              first stages of getting to know someone.
            </p>
            <a href='/resources/making-connections' className='learn-button'>
              Learn More
            </a>
          </div>

          {/* Staying Safe */}
          <div className='resource-card'>
            <h2>Staying Safe</h2>
            <p>
              Your safety is our priority. Explore our in-depth safety
              guidelines for both online interactions and in-person meetings.
            </p>
            <a href='/resources/staying-safe' className='learn-button'>
              Read Tips
            </a>
          </div>

          {/* Advanced Features */}
          <div className='resource-card'>
            <h2>Using Advanced Features</h2>
            <p>
              Unlock the power of Mingo's advanced tools like enhanced filters,
              chat preferences, and exclusive matching features.
            </p>
            <a href='/resources/advanced-features' className='learn-button'>
              Discover
            </a>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className='learn-testimonials'>
          <h2>What Our Users Say</h2>
          <blockquote>
            "The resources on Mingo helped me build a standout profile and
            connect with amazing people!" – Sarah J.
          </blockquote>
          <blockquote>
            "I always felt safe thanks to Mingo's detailed safety guidelines.
            Highly recommend!" – Mark R.
          </blockquote>
        </section>

        {/* Footer */}
        <footer className='learn-footer'>
          <p>
            Have questions? Visit our <a href='/support'>Support Page</a> or
            contact us directly at{' '}
            <a href='mailto:support@mingo.com'>support@mingo.com</a>.
          </p>
        </footer>
      </div>
    </>
  )
}

export default Learn
