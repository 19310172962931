import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import OnBoarding from './pages/OnBoarding'
import SwipePage from './pages/SwiperPage'
import Learn from './pages/Learn'
import Safety from './pages/Safety'
import Support from './pages/Support'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useCookies } from 'react-cookie'
// import { UserProvider } from './context/UserContext'

const App = () => {
  const [cookies] = useCookies(['user'])
  const authToken = cookies.AuthToken

  return (
    // <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        {authToken && <Route path='/dashboard' element={<Dashboard />} />}
        {authToken && <Route path='/onboarding' element={<OnBoarding />} />}
        {authToken && <Route path='/swipe' element={<SwipePage />} />}
        <Route path='/learn' element={<Learn />} />
        <Route path='/safety' element={<Safety />} />
        <Route path='/support' element={<Support />} />
        {/* <Route path='*' element={<div>404 Not Found</div>} /> */}
      </Routes>
    </BrowserRouter>
    // </UserProvider>
  )
}

export default App
