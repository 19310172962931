import '../styles/chat.css'

const Chat = ({ descendingOrderMessages = [] }) => {
  if (!descendingOrderMessages.length) {
    return (
      <div className='no-messages'>
        No messages yet. Start the conversation!
      </div>
    )
  }

  return (
    <div className='chat-display'>
      {descendingOrderMessages.map((message, index) => (
        <div key={index} className='chat-message'>
          <div className='chat-message-header'>
            <div className='img-container'>
              <img src={message.img} alt={`${message.name} profile`} />
            </div>
            <p className='chat-message-text'>{message.message}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Chat
