import { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
const API_BASE_URL = 'https://mingle-server-liard.vercel.app'

const AuthModal = ({ setShowModal, isSignUp }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [cookies, setCookie] = useCookies()

  const navigate = useNavigate()

  const handleClick = () => {
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (isSignUp && password !== confirmPassword) {
      setError('Passwords need to match!')
      return
    }

    try {
      const endpoint = isSignUp ? 'signup' : 'login'
      const response = await axios.post(`${API_BASE_URL}/${endpoint}`, {
        email,
        password,
      })

      if (response.status === 201) {
        setCookie('AuthToken', response.data.token, { path: '/' })
        setCookie('UserId', response.data.userId, { path: '/' })
        navigate(isSignUp ? '/onboarding' : '/dashboard')
        window.location.reload()
      }
    } catch (error) {
      setError('An error occurred. Please try again.')
      console.error(error)
    }
  }

  return (
    <div className='auth-modal'>
      <div
        className='close-icon'
        onClick={handleClick}
        role='button'
        tabIndex={0}
      >
        &times;
      </div>
      <h2>{isSignUp ? 'Create Account' : 'Log In'}</h2>
      <p>
        By clicking Log In, you agree to our terms. Learn how we process your
        data in our Privacy Policy and Cookie Policy.
      </p>
      <form onSubmit={handleSubmit}>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          id='email'
          name='email'
          placeholder='Enter your email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor='password'>Password</label>
        <input
          type='password'
          id='password'
          name='password'
          placeholder='Enter your password'
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isSignUp && (
          <>
            <label htmlFor='confirm-password'>Confirm Password</label>
            <input
              type='password'
              id='confirm-password'
              name='confirm-password'
              placeholder='Confirm your password'
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </>
        )}
        <button className='primary-button' type='submit'>
          {isSignUp ? 'Sign Up' : 'Log In'}
        </button>
        {error && <p className='error-message'>{error}</p>}
      </form>
      <hr />
      <h2>Get the App</h2>
    </div>
  )
}

export default AuthModal
